import { auth, firestore } from "containers/firebase";
import { COLLECTION } from "shared/strings/firebase";
import { head } from "utils";
import { firestoreToArray, getFirestoreContentWhereFieldInArray } from "./helpers";
import firebase from "firebase/compat/app";
import { TRANSACTION_TYPES } from "./users";
import dateUtils from "utils/dateUtils";
import axios from "axios";
import batchUtils from "utils/batchUtils";
import _ from "lodash";
import { TENANTS_STATUSES } from "constants/statuses";

const { REACT_APP_CLOUD_FUNCTION_API_URL } = process.env;

export const prepareTenantProductCategoryToData = (category) => {
  const preparedCategory = !category
    ? {}
    : {
      id: category.id || "",
      categoryName: category.categoryName || '',
      logoUrl: category.logoUrl || '',
      description: category.description || '',
    };
  return preparedCategory;
}

export const createTenantProduct = async (tenantId, data) => {
  const newDoc = firestore.collection(COLLECTION.Tenants).doc(tenantId).collection(COLLECTION.products).doc();
  const tenantDoc = firestore.collection(COLLECTION.Tenants).doc(tenantId);

  const GREENPOINTS_CURRENCY = 100;

  if (data.steps_en == undefined) {
    data.steps_en = {
      step1: "",
      step2: "",
      step3: "",
      step4: "",
      step5: ""

    }
  }

  await newDoc.set({
    id: newDoc.id,
    images: data?.images,
    title_en: data?.title_en,
    title_fr: data?.title_fr,
    bodyText_en: data?.bodyText_en,
    bodyText_fr: data?.bodyText_fr,
    isFeatured: data?.isFeatured || false,
    isNew: data?.isNew || false,
    isOnSale: data?.isOnSale || false,
    sku: data?.sku,
    displayingOrder: data?.displayOrder,
    cityId: data?.cityId,
    tenantId: tenantId,
    tenantName: data?.tenantName,
    productUrl: data?.productUrl,
    price: Number(+data?.price),
    greenpoints: Number(data?.price * GREENPOINTS_CURRENCY),
    stock: Number(data?.stock),
    initialStock: Number(data?.stock),
    availability: data?.availability,
    barCode: data?.barCode,
    categories: data?.categories,
    status: data?.status,
    createdOn: new Date(),
    updatedOn: new Date(),
    isApproved: false,
    steps: data?.steps,
    steps_en: data?.steps_en,
    isCoupon: data?.isCoupon || false,
    isDelivery: data?.isDelivery || false,
    isBarCode: data?.isBarCode || false,
    isUniqueBarcode: data?.isUniqueBarcode || false,
    isQrCode: data?.isQrCode || false,
    isUniqueQrcode: data?.isUniqueQrcode || false,
    uniqueBarCodes: data?.uniqueBarCodes || [],
    expirationDate: data?.expirationDate || null,
    maxTransactionsPerPeriod: {
      value: +data.maxTransactionsPerPeriod.value,
      period: data.maxTransactionsPerPeriod.period
    }
  });

  if (data.uniqueBarCodes.length > 0) {
    await batchUtils.batchLimitParallel({
      firestore: firestore,
      items: data.uniqueBarCodes,
      limit: 250,
      onEach: async (barCode, batch) => {
        const docRef = firestore.collection(COLLECTION.Tenants).doc(tenantId).collection(COLLECTION.products).doc(newDoc.id).collection(COLLECTION.barCodes).doc(barCode);
        batch.set(docRef, {
          createdAt: new Date(),
          status: 'active',
          barCode,
        }, { merge: true });
      },
    });
  }

  return tenantDoc.update({ productCount: firebase.firestore.FieldValue.increment(1) })
};

export const editTenantProduct = async (tenantId, productId, data) => {
  const product = firestore.collection(COLLECTION.Tenants).doc(tenantId).collection(COLLECTION.products).doc(productId);

  const GREENPOINTS_CURRENCY = 100;

  if (data.steps_en == undefined) {
    data.steps_en = {
      step1: "",
      step2: "",
      step3: "",
      step4: "",
      step5: ""

    }
  }

  await product.update({
    images: data?.images,
    title_en: data?.title_en,
    title_fr: data?.title_fr,
    bodyText_en: data?.bodyText_en,
    bodyText_fr: data?.bodyText_fr,
    isFeatured: data?.isFeatured || false,
    isNew: data?.isNew || false,
    isOnSale: data?.isOnSale || false,
    sku: data?.sku,
    displayingOrder: data?.displayOrder,
    cityId: data?.cityId,
    tenantId: tenantId,
    tenantName: data?.tenantName,
    productUrl: data?.productUrl || "",
    price: Number(+data?.price),
    greenpoints: Number(data?.price * GREENPOINTS_CURRENCY),
    stock: Number(data?.stock),
    initialStock: Number(data?.stock),
    availability: data?.availability,
    barCode: data?.barCode || "",
    categories: data?.categories,
    status: data?.status,
    steps: data?.steps,
    steps_en: data?.steps_en,
    isApproved: data?.isApproved || false,
    isCoupon: data?.isCoupon || false,
    isDelivery: data?.isDelivery || false,
    isBarCode: data?.isBarCode || false,
    isUniqueBarcode: data?.isUniqueBarcode || false,
    isQrCode: data?.isQrCode || false,
    isUniqueQrcode: data?.isUniqueQrcode || false,
    uniqueBarCodes: data?.uniqueBarCodes || [],
    updatedOn: new Date(),
    expirationDate: data?.expirationDate || null,
    maxTransactionsPerPeriod: {
      value: +data.maxTransactionsPerPeriod.value,
      period: data.maxTransactionsPerPeriod.period
    }
  });

  if (data.uniqueBarCodes.length > 0) {
    await batchUtils.batchLimitParallel({
      firestore: firestore,
      items: data.uniqueBarCodes,
      limit: 250,
      onEach: async (barCode, batch) => {
        const docRef = firestore.collection(COLLECTION.Tenants).doc(tenantId).collection(COLLECTION.products).doc(productId).collection(COLLECTION.barCodes).doc(barCode);
        const data = (await docRef.get()).data();
        batch.set(docRef, {
          updatedAt: new Date(),
          ...data,
          status: data?.status || 'active',
          barCode,
        }, { merge: true });
      },
    });
  }
};

export const getTenantProducts = async (tenantId, params = {}) => {
  const { orderBy = "updatedOn", limit } = params;

  let query = firestore.collection(COLLECTION.Tenants).doc(tenantId).collection(COLLECTION.products);

  if (orderBy) {
    query = query.orderBy(orderBy, "desc");
  }

  if (limit) {
    query = query.limit(limit);
  }

  return query.get().then(firestoreToArray);
};

export const getTenantProductGroupsById = productId => {
  return firestore.collectionGroup(COLLECTION.products)
    .where('id', '==', productId)
    .get()
    .then(res => {
      return firestoreToArray(res).map((item, idx) => ({ ...item, tenantId: res.docs[idx].ref.parent.parent.id }))
    })
    .then(head);
};

export const getTenantProductById = async (productId, tenantId) => {
  if (!tenantId) {
    return getTenantProductGroupsById(productId);
  }

  const query = firestore
    .collection(COLLECTION.Tenants)
    .doc(tenantId)
    .collection(COLLECTION.products)
    .doc(productId);

  return query.get().then((doc) => ({ ...doc.data(), id: doc.id }));
};

const getTenantProductBarCodesForCity = async (productId) => {
  const products = await firestore.collectionGroup(COLLECTION.products).where("id", "==", productId).get();
  const product = products.docs[0];
  return product.ref.collection(COLLECTION.barCodes).get().then(firestoreToArray).then((array) => array.map(i => ({ ...i, tenantId: product.ref.parent.parent.id, isUniqueQrCode: product.data().isUniqueQrCode })));
};

export const getTenantProductBarCodes = async (productId, tenantId) => {
  if (!tenantId) {
    return getTenantProductBarCodesForCity(productId);
  }
  const query = firestore
    .collection(COLLECTION.Tenants)
    .doc(tenantId)
    .collection(COLLECTION.products)
    .doc(productId)
    .collection(COLLECTION.barCodes);

  return query.get().then(firestoreToArray);
};

export const updateTenantProductBarCode = async (productId, tenantId, barCode, data) => {
  return firestore
    .collection(COLLECTION.Tenants)
    .doc(tenantId)
    .collection(COLLECTION.products)
    .doc(productId)
    .collection(COLLECTION.barCodes)
    .doc(barCode)
    .update(data);
};

export const getOrderIdFromOrderNumber = (orderNumber, tenantId) => {

  const query = firestore.collection(COLLECTION.Tenants)
    .doc(tenantId)
    .collection(COLLECTION.orders)
    .where('orderNumber', '==', orderNumber)
    .limit(1);

  return query.get().then((querySnapshot) => {
    if (!querySnapshot.empty) {

      return querySnapshot.docs[0].id;
    } else {

      return null;
    }
  });
};

export const getTenantProductsForCity = async (params = {}) => {
  const { limit, cityId } = params;
  let query = firestore.collectionGroup(COLLECTION.products);

  if (cityId) {
    query = query.where('cityId', "==", cityId);
  }

  if (limit) {
    query = query.limit(limit);
  }

  return query.get().then(res => {
    const results = firestoreToArray(res);
    results.sort((a, b) => new Date(b.updatedOn?.toDate()) - new Date(a.updatedOn?.toDate()));

    return results;
  });
};

export const updateTenantProductFields = async (productId, payload) => {
  const { docs } = await firestore.collectionGroup(COLLECTION.products)
    .where('id', '==', productId)
    .get();

  if (docs.length !== 1) {
    throw new Error(`Product ${productId} doesn't exists`)
  }

  const productToChange = head(docs);

  if (payload.uniqueBarCodes?.length > 0) {
    await batchUtils.batchLimitParallel({
      firestore: firestore,
      items: payload.uniqueBarCodes,
      limit: 250,
      onEach: async (barCode, batch) => {
        const docRef = firestore.collection(COLLECTION.Tenants).doc(payload.tenantId).collection(COLLECTION.products).doc(productId).collection(COLLECTION.barCodes).doc(barCode);
        const data = (await docRef.get()).data();
        batch.set(docRef, {
          updatedAt: new Date(),
          ...data,
          status: data?.status || 'active',
          barCode,
        }, { merge: true });
      },
    });
  }

  return productToChange.ref.update({
    ..._.omitBy(payload, _.isUndefined),
    updatedOn: new Date(),
  });
};

export const getTenantOrders = async (user, params = {}) => {
  const { tenantId, cityId } = user;

  if (!tenantId) {
    return getTenantOrdersForCity(cityId, params)
  }
  const { orderBy = "createdAt", limit, logType, startDate, pageSize, startAfter } = params;

  let query = await firestore.collection(COLLECTION.Tenants).doc(tenantId).collection(COLLECTION.orders);

  if (logType && startDate) {
    let toDate = dateUtils.getEndDateByLogTypeAndStartDate(logType, startDate)
    query = query
      .where('createdAt', '>=', dateUtils.convertDateToFirestoreTimestamp(startDate))
      .where('createdAt', '<=', dateUtils.convertDateToFirestoreTimestamp(toDate));
  }

  if (orderBy) {
    query = query.orderBy(orderBy, "desc");
  }

  if (limit) {
    query = query.limit(limit);
  }

  if (pageSize) {
    if (startAfter) {
      query = query.startAfter(startAfter);
    }
    query = query.limit(pageSize);
  }

  return query.get().then(firestoreToArray).catch((error) => console.log(`error`, error));
};

export const getTenantOrderDetailsById = async (tenantId, orderId) => {
  if (!tenantId) {
    return getTenantOrderGroupsById(orderId)
  }

  return await firestore.collection(COLLECTION.Tenants).doc(tenantId).collection(COLLECTION.orders).doc(orderId).get().then(order => order.data());
}

export const getTenantOrdersForCity = (cityId, params = {}) => {
  const { orderBy = "createdAt", limit, startDate, logType, pageSize, startAfter} = params;
  let query = firestore.collectionGroup(COLLECTION.orders)
    .where('cityId', '==', cityId);

  if (logType && startDate) {
    let toDate = dateUtils.getEndDateByLogTypeAndStartDate(logType, startDate)
    query = query
      .where('createdAt', '>=', dateUtils.convertDateToFirestoreTimestamp(startDate))
      .where('createdAt', '<=', dateUtils.convertDateToFirestoreTimestamp(toDate));
  }

  if (orderBy) {
    query = query.orderBy(orderBy, "desc");
  }

  if (limit) {
    query = query.limit(limit);
  }

  
  if (pageSize) {
    if (startAfter) {
      query = query.startAfter(startAfter);
    }
    query = query.limit(pageSize);
  }

  return query.get().then(firestoreToArray);
};

export const getTenantOrderGroupsById = orderId => {

  return firestore.collectionGroup(COLLECTION.orders)
    .where('id', '==', orderId)
    .get()
    .then(firestoreToArray)
    .then(head);
};

export const getTenantInfoById = async (tenantId) => {
  return await firestore.collection(COLLECTION.Tenants).doc(tenantId).get().then(ten => ({ ...ten.data(), id: ten.id }));
};

export const getConnectedCityTenants = async (cityId) => {
  const tenants = await firestore.collection(COLLECTION.Tenants)
    .where('cityId', '==', cityId)
    .get()
    .then(firestoreToArray);

  return tenants
};

export const tenantUpdateOrder = async (tenantId, orderId, updateData) => {
  return await firestore.collection(COLLECTION.Tenants)
    .doc(tenantId)
    .collection(COLLECTION.orders)
    .doc(orderId)
    .update(updateData)
};

export const deleteTenantProduct = async (tenantId, productId) => {
  const tenantDoc = firestore.collection(COLLECTION.Tenants).doc(tenantId);

  await tenantDoc.collection(COLLECTION.products).doc(productId).collection(COLLECTION.barCodes).get().then(async (querySnapshot) => {
    await batchUtils.batchLimitParallel({
      firestore: firestore,
      items: querySnapshot.docs,
      limit: 250,
      onEach: async (doc, batch) => {
        batch.delete(doc.ref);
      },
    });
  });
  await tenantDoc.collection(COLLECTION.products).doc(productId).delete()
  return tenantDoc.update({ productCount: firebase.firestore.FieldValue.increment(-1) })
}

export const getTenantCategoryOptions = async (tenantId) => {
  return await firestore.collection(COLLECTION.Tenants)
    .doc(tenantId)
    .collection(COLLECTION.categories)
    .get()
    .then(firestoreToArray)
};

export const getTenantTransactionsByTime = (tenantId, fromTimeForGettingTransactions, toTimeForGettingTransactions) => {
  return firestore.collectionGroup(COLLECTION.orders)
    .where('createdAt', '>', fromTimeForGettingTransactions)
    .where('createdAt', '<', toTimeForGettingTransactions)
    .where('transactionType', '==', TRANSACTION_TYPES.SHOPPING)
    .where('transactionDetails.tenantId', '==', tenantId)
    .get()
    .then(firestoreToArray)
};

export const getTenantData = (tenantId) => {
  return firestore
    .collection(COLLECTION.Tenants)
    .doc(tenantId)
    .get()
    .then((snap) => {
      if (snap.exists) {
        return { ...snap.data(), id: snap.id };
      } else {
        return getPendingTenantData(tenantId);
      }
    });
};

export const getPendingTenantData = (tenantId) => {
  return firestore
    .collection(COLLECTION.Tenants)
    .doc(tenantId)
    .get()
    .then((snap) => ({ status: TENANTS_STATUSES.pending, ...snap.data(), id: snap.id }));
};

export const getAllTenants = () => {
  return firestore.collection(COLLECTION.Tenants)
    .get()
    .then(firestoreToArray);
}

export const sendOrderSentEmail = (orderId, tenantId) => {
  if (!orderId || !tenantId) {
    throw 'orderId and tenantId are required';
  }

  return auth.currentUser.getIdToken().then((token) => {
    axios.post(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/messagingApi/sendOrderSentEmail`,
      { orderId: orderId, tenantId: tenantId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }).catch((error) => {
    console.log(error);
  });


}

export const createPendingTenant = async (data) => {
  const doc = firestore.collection(COLLECTION.Tenants).doc();
  await doc.set(data, { merge: true });
  return doc.id;
}

export const deleteTenant = async (tenantId, status = 'pending') => {
  const col = COLLECTION.Tenants;

  await auth.currentUser.getIdToken().then((token) => {

    return axios.post(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/app/v2/delete-doc-with-subCollections`,
      { collectionPath: col, docId: tenantId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }).catch((error) => {
    console.log(error);
    console.log(error.message);
  });
  return true;
}

export const moveTenantPendingData = async (pendingTenantId, newTenantId, updateDataForAllSubDocs) => {
  await auth.currentUser.getIdToken().then((token) => {
    return axios.post(
      `${REACT_APP_CLOUD_FUNCTION_API_URL}/app/v2/move-subCollections`,
      {
        fromCollectionPath: COLLECTION.Tenants,
        fromDocId: pendingTenantId,
        toCollectionPath: COLLECTION.Tenants,
        toDocId: newTenantId,
        updateDataNested: updateDataForAllSubDocs,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }).catch((error) => {
    console.log(error);
  });
}

export const createUserTransaction = async ({ productData, userData }) => {
  const transactionData = {
    userId: userData.id,
    orderId: "327853", //TODO: generate valid order id
    greenpoints: 0,
    createdBy: "ADMIN",
    transactionDetails: {
      productNameFr: productData.title_fr || "",
      categoryName: productData.category?.categoryName || "",
      sku: productData.sku,
      productId: productData.id,
      categoryId: productData.category?.id || "",
      imageUrl: productData.image || "",
      tenantName: productData.tenantName || "",
      productName: productData.title_en || "",
      tenantId: productData.tenantId,
    },
    price: +productData.price || 0,
  };

  await axios.post(`${REACT_APP_CLOUD_FUNCTION_API_URL}/app/v2/transaction/spend`, transactionData);
};

export const createProductTransactionsByAdmin = async ({ usersEmails, productData }) => {
  const usersCollection = firestore.collection(COLLECTION.Users);

  // Fonction pour rechercher les utilisateurs avec un tableau d'emails donné
  const fetchUsersByEmails = async (emails) => {

    return await getFirestoreContentWhereFieldInArray({
      collectionRef: usersCollection,
      field: "email",
      valuesToSearchIn: emails,
    });
  };

  let usersToCreateOrders = await fetchUsersByEmails(usersEmails);

  if (usersToCreateOrders.length === 0) {

    console.log("Aucun utilisateur trouvé avec les emails fournis.");
    return false;

  }

  // Créer les transactions pour les utilisateurs trouvés
  for (const user of usersToCreateOrders) {
    await createUserTransaction({ productData, userData: user });
  }

  return true;
};

// Fonction utilitaire pour mettre en majuscule la première lettre d'un email
function capitalizeFirstLetter(email) {
  return email.charAt(0).toUpperCase() + email.slice(1);
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { login, logout, setDetails } from "../../redux/actions/authAction";
import { Firebase } from "../../containers/firebase";
import { COLLECTION } from "shared/strings/firebase";
import { getOrganizationById } from "services/organizations";
import { getCityById } from "services/cities";

export const USER_ID = 0;
export const USER_DATA = 1;

export default function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.auth.user);

  const firebase = React.useMemo(() => new Firebase(), []);

  React.useEffect(() => {
    try {
      firebase._onAuthStateChanged((user) => {
        if (!user) {
          console.log("User not found");
          dispatch(logout());
        } else {
          Promise.all([
            firebase._findById(COLLECTION.Cities, user.uid),
            firebase._findById(COLLECTION.Organisations, user.uid),
            firebase._findById(COLLECTION.Tenants, user.uid),
            firebase._findById(COLLECTION.externalUsers, user.uid),
          ])
            .then(async (snapshots) => {
              const [city, organisation, tenant, external] = snapshots;
              const findData = snapshots?.find((snapshot) => snapshot.docs.length === 1);
              // Do not login when details is not found
              if (!findData) {
                throw new Error("Duplicate account");
              }

              let role = '';
              let extraDataForExternal = {};

              if (!city.empty) {
                role = 'city';
              }
              if (!organisation.empty) {
                role = 'organisation';
                organisation.docs[0].ref.update({lastLogin: firebase.firebase.firestore.Timestamp.now()})
              }
              if (!tenant.empty) {
                role = 'tenant';
                tenant.docs[0].ref.update({lastLogin: firebase.firebase.firestore.Timestamp.now()})
              }
              if (!external.empty) {
                role = findData.docs[0].data().externalFor;

                if (role === 'organisation') {
                  const orgData = await getOrganizationById(findData.docs[0].data().organisationId);
                  extraDataForExternal = {...orgData};
                }
                if (role === 'city') {
                  const cityData = await getCityById(findData.docs[0].data().cityId);
                  extraDataForExternal = {...cityData};
                }
                external.docs[0].ref.update({lastLogin: firebase.firebase.firestore.Timestamp.now()})
              }

              const data = { ...extraDataForExternal, ...findData.docs[0].data(), role };
              
              // Do not dispatch if user is same
              if (JSON.stringify(user?.uid) !== atob(userID)) {
                dispatch(setDetails(data));
                dispatch(login(data?.id));
              }
            })
            .catch(() => {
              dispatch(logout());
            });
        }
      });
    } catch (error) {
      console.log("Auth error", error);
      dispatch(logout());
    }
  }, [firebase, dispatch, userID]);

  return children;
}

export function useAuth() {
  const userFromStore = useSelector((state) => state.auth.user);
  const userDetailsFromStore = useSelector((state) => state.auth.data);
  const userAdminDataFromStore = useSelector((state) => state.auth.adminData);

  try {
    const decodeUser = userFromStore?.length > 0 ? atob(userFromStore) : null;
    const user = JSON.parse(decodeUser);
    const details = userDetailsFromStore;
    const adminData = userAdminDataFromStore;

    return [user, details, adminData];
  } catch (error) {
    return null;
  }
}

const gpColors = {
    white: 'white',
    darkGrey: '#646777',
    lightBlue: '#50b6e2',
    lightGreen: '#b7dd5b',
    lightPurple: '#8749cd',
    lightGrey25: '#edeef1',
    darkBlue: '#1576ca',
    green: '#4caf50',
    orange: '#ED7D31'
};

export default gpColors;

// Go to meta key inside each locale translation
export const meta = Object.freeze({
  // If in case we have additional roles in the future
  organisation: {
    Greenplay: {
      title: "meta.organisation.greenplay",
      description: "meta.organisation.greenplay",
    },
    Login: {
      title: "meta.organisation.login",
      description: "meta.organisation.login_description",
    },
    Register: {
      title: "meta.organisation.register",
      description: "meta.organisation.register_description",
    },
    ForgotPassword: {
      title: "meta.organisation.forgot_password",
      description: "meta.organisation.forgot_password_description",
    },
    Dashboard: {
      title: "meta.organisation.dashboard",
      description: "meta.organisation.dashboard_description",
    },
    ChallengeEdit: {
      title: "meta.organisation.challengeEdit",
      description: "meta.organisation.challengeEdit_description",
    },
    ChallengeInfo: {
      title: "meta.organisation.challengeInfo",
      description: "meta.organisation.challengeInfo_description",
    },
    ChallengeCreate: {
      title: "meta.organisation.challengeCreate",
      description: "meta.organisation.challengeCreate_description",
    },
    ChallengeDashboard: {
      title: "meta.organisation.challengeDashboard",
      description: "meta.organisation.challengeDashboard_description",
    },
    ChallengeList: {
      title: "meta.organisation.challengeList",
      description: "meta.organisation.challengeList_description",
    },
    Profile: {
      title: "meta.organisation.profile",
      description: "meta.organisation.profile_description",
    },
    Branch: {
      title: "meta.organisation.branch",
      description: "meta.organisation.branch_description",
    },
    Notification: {
      title: "meta.organisation.notification",
      description: "meta.organisation.notification_description",
    },
    Notifications: {
      title: "meta.organisation.notifications",
      description: "meta.organisation.notifications_description",
    },
    NotificationDetails: {
      title: "meta.organisation.notificationDetails",
      description: "meta.organisation.notificationDetails_description",
    },
    Email: {
      title: "meta.organisation.email",
      description: "meta.organisation.email_description",
    },
    EmailDetails: {
      title: "meta.organisation.emailDetails",
      description: "meta.organisation.emailDetails_description",
    },
    BranchDetails: {
      title: "meta.organisation.branchDetails",
      description: "meta.organisation.branchDetails_description",
    },
    FAQ: {
      title: "meta.organisation.FAQ",
      description: "meta.organisation.FAQ_description",
    },
    Settings: {
      title: "meta.organisation.settings",
      description: "meta.organisation.settings_description",
    }
  },
  city: {
    Login: {
      title: "meta.organisation.login",
      description: "meta.organisation.login_description",
    },
    Register: {
      title: "meta.city.register",
      description: "meta.city.register_description",
    },
    Dashboard: {
      title: "meta.city.dashboard",
      description: "meta.city.dashboard_description",
    },
    Profile: {
      title: "meta.city.profile",
      description: "meta.city.profile_description",
    },
    Branch: {
      title: "meta.city.branch",
      description: "meta.city.branch_description",
    },
    Notification: {
      title: "meta.city.notification",
      description: "meta.city.notification_description",
    },
    Badge: {
      title: "meta.city.badge",
      description: "meta.city.badge_description",
    },
    News: {
      title: "meta.city.news",
      description: "meta.city.news_description",
    },
    HappyHours: {
      title: "meta.city.happy_hours",
      description: "meta.city.happy_hours_description",
    },
    NewsDetails: {
      title: "meta.city.news",
      description: "meta.city.news_description",
    },
    Email: {
      title: "meta.city.email",
      description: "meta.city.email_description",
    },
    FAQ: {
      title: "meta.city.FAQ",
      description: "meta.city.FAQ_description",
    },
    manage: {
      title: "meta.city.manage",
      description: "meta.city.manage_description",
    },
  },
  tenant: {
    Dashboard: {
      title: "meta.organisation.dashboard",
      description: "meta.organisation.dashboard_description",
    },
    tenantKBS: {
      title: "meta.organisation.dashboard",
      description: "meta.organisation.dashboard_description",
    },
  },
  404: {
    title: "meta.page_not_found",
    description: "meta.page_not_found_description",
  },
});

export const ActivityStatus = Object.freeze({
  IDLE: "IDLE",
  PENDING: "PENDING",
  RESOLVED: "RESOLVED",
  REJECTED: "REJECTED",
  NO_PERMISSION: "NO_PERMISSION",
});

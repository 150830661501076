import common_fr from './fr/common.json';
import common_en from './en/common.json';

export default {
	en: {
		common: common_en,
	},
	fr: {
		common: common_fr,
	}
};
